'use client';

import { useSwiperSlide } from 'swiper/react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CTA from '@/app/ui/Buttons/CTA';
import Link from 'next/link';

export default function Modal({ data, isModalShowing, showModal, hideModal }) {
	const swiperSlide = useSwiperSlide();

	return (
		<>
			{swiperSlide.isActive && isModalShowing === false && (
				<Link href={data?.Website} target="_blank">
					<CTA
						// onClick={showModal}
						label="More Info"
						withBackgroundColor={true}
						sx={{
							height: '32px',
							width: '144px',
							position: 'absolute',
							inset: { xs: 'auto 1rem 2rem auto', md: 'auto 2rem 2rem auto' }
						}}
					/>
				</Link>
			)}
			{/* {swiperSlide.isActive && isModalShowing && (
				<Box
					sx={{
						border: theme => `1px solid ${theme.palette.divider}`,
						position: 'absolute',
						bottom: 0,
						right: 0,
						width: 'auto',
						height: { xs: '100%', md: '65%' },
						background: 'rgba(0,0,0,0.8)',
						backdropFilter: 'blur(15px)',
						overflow: 'auto',
						p: 2
					}}>
					<Box
						sx={{
							position: 'sticky',
							height: '45px',
							top: 0,
							right: 0,
							display: 'flex',
							alignItems: 'center'
						}}>
						<IconButton sx={{ ml: 'auto', backdropFilter: 'blur(10px)' }} onClick={hideModal}>
							<CloseIcon />
						</IconButton>
					</Box>
					<Grid container spacing={2}>
						<Grid item md={6}>
							<Typography
								gutterBottom
								dangerouslySetInnerHTML={{ __html: data.MoreInfoLeftContent }}></Typography>
						</Grid>
						<Grid item md={6}>
							<Typography
								gutterBottom
								dangerouslySetInnerHTML={{ __html: data.MoreInfoRightContent }}></Typography>
						</Grid>
					</Grid>
				</Box>
			)} */}
		</>
	);
}
